<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <tickets
        :numero-poste="numeroPoste"
        :detail="showDetail"
        :columnas-tickets="columnasTickets"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Tickets from '@/views/tickets/Tickets.vue'

export default {
  components: {
    Tickets,
    BCard,
  },
  props: {
    numeroPoste: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDetail: false,
      columnasTickets: [
        {
          label: 'No.',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Poste',
          field: 'punto.numeroPoste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Canal',
          field: 'canal',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fuente',
          field: 'fuente.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Falla',
          field: 'falla.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Etapa',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Ingreso',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
