<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-asignar
      variant="relief-primary"
      block
      @click="verDetallePoste"
    >
      Detalle
    </b-button>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      title="Punto de Iluminación"
    >
      <b-card class="card-poste">
        <levantamiento-de-datos
          id="punto.id"
          name="LevantamientoDeDatos"
          :is-read-only="true"
          :is-new="true"
          :punto-iluminacion="punto"
          :detalle="detalle"
        />
      </b-card>
    </b-modal>
  </div>

</template>

<script>
import { BModal, BCard, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ModalLevantamientoDatos',
  components: {
    BModal,
    BCard,
    BButton,
    LevantamientoDeDatos: () => import('./LevantamientoDeDatos.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    punto: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    detalle: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    verDetallePoste() {
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
    },
  },
}
</script>
