<template>
  <div>
    <!-- Table Container Card -->
    <b-row>
      <b-col cols="12">
        <b-card class="card-poste">
          <b-tabs
            justified
          >
            <b-tab title="1. Coordinación Aseguradora">
              <coordinacion-aseguradora-vue
                :columnas-coord="columnasCoord"
                :detail="showDetail"
              />
            </b-tab>
            <b-tab
              title="2. Falla BT"
              active
            >
              <falla-bt-index
                :numero-poste="numeroPoste"
                :columnas-fallas="columnasFallas"
                :detail="showDetail"
                :apply-date-filter="true"
              />
            </b-tab>
            <b-tab title="4. Conservación">
              <conservacion-index
                :numero-poste="numeroPoste"
                :columnas-conservacion="columnasConservacion"
                :detail="showDetail"
              />
            </b-tab>
            <b-tab title="5. Brecha de Iluminación">
              <brecha-iluminacion-index
                :numero-poste="numeroPoste"
                :columnas-brecha="columnasBrecha"
                :detail="showDetail"
              />
            </b-tab>
            <b-tab title="8. Aplomado de Poste">
              <aplomado-poste-index
                :numero-poste="numeroPoste"
                :columnas-aplomado="columnasAplomado"
                :detail="showDetail"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol, BTab, BTabs,
} from 'bootstrap-vue'
import ConservacionIndex from '@/views/mantenimiento/conservacion/conservacionIndex.vue'
import FallaBtIndex from '@/views/mantenimiento/fallaLineaBt/fallaBtIndex.vue'
import BrechaIluminacionIndex from '@/views/mantenimiento/brechaIluminacion/brechaIluminacionIndex.vue'
import AplomadoPosteIndex from '@/views/mantenimiento/aplomadoPoste/aplomadoPosteIndex.vue'
import Inspecciones from '@/components/PuntosIluminacion/Inspecciones.vue'
import CoordinacionAseguradoraVue from '@/views/mantenimiento/coordinacionAseguradora/coordinacion-aseguradora-index.vue'

export default {
  components: {
    CoordinacionAseguradoraVue,
    Inspecciones,
    AplomadoPosteIndex,
    BrechaIluminacionIndex,
    FallaBtIndex,
    ConservacionIndex,
    BCard,
    BRow,
    BCol,
    BTab,
    BTabs,
  },
  props: {
    numeroPoste: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      idUrl: '',
      showDetail: false,
      columnasFallas: [
        {
          label: 'Correlativo',
          field: 'correlativo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Poste',
          field: 'posteReferencia',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Falla',
          field: 'tipoFalla.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Técnico Encargado',
          field: 'usuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnasConservacion: [
        {
          label: 'Correlativo',
          field: 'correlativo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Poste',
          field: 'posteReferencia',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Trabajo(s) Realizado(s)',
          field: 'trabajosRealizados',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Técnico Encargado',
          field: 'usuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnasBrecha: [
        {
          label: 'No. Poste',
          field: 'poste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Trabajos Realizados',
          field: 'trabajosRealizados',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Brigada',
          field: 'brigada.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnasAplomado: [
        {
          label: 'No. Poste',
          field: 'poste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Brigada',
          field: 'brigada.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnasCoord: [
        {
          label: 'No. Poste',
          field: 'poste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Brigada',
          field: 'brigada.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
