<template>
  <div>
    <b-row>
      <b-col :cols="isNew?12:7">
        <validation-observer ref="simpleRules">
          <b-form>
            <BCard>
              <b-row>
                <b-col cols="4">
                  <h2 class="text-primary">
                    POSTE
                  </h2>
                </b-col>
                <b-col cols="1" />
                <b-col cols="7">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.modal-asignar
                      variant="relief-primary"
                      block
                      @click="verDetallePoste"
                    >
                      Detalle del Punto
                    </b-button>
                    <b-modal
                      v-model="verDialogoDocumento"
                      ok-only
                      ok-variant="primary"
                      ok-title="Aceptar"
                      modal-class="modal-primary"
                      centered
                      title="Punto de Iluminación"
                    >
                      <b-card class="card-poste">
                        <detail-punto
                          v-if="puntoIluminacion"
                          :id="puntoIluminacion.id"
                          :punto="puntoIluminacion"
                        />
                      </b-card>
                    </b-modal>
                  </div>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Propiedad <code>Exclusiva</code> o de <code>EEGSA</code>
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Propiedad"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.propiedadPoste"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="propiedadesPoste"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Tipo de Poste:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de Poste"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.tipoPoste"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="tiposPoste"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Tamaño de Poste:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tamaño de Poste"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.tamañoPoste"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="tamañosPoste"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <h5 class="text-primary">
                    Observaciones de Poste:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Observaciones de Poste"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.observacionesPoste"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        multiple
                        :options="observacionesPoste"
                        :editable="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <hr>
              <br>
              <b-row>
                <b-col cols="12">
                  <h2 class="text-primary">
                    BRAZO
                  </h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Cantidad:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Cantidad"
                      rules="required"
                    >
                      <v-select
                        v-model="cantidadBrazos"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="cantidad"
                        :disabled="editable"
                        label="nombre"
                        @input="changeCombo('brazo')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(cantidad, index) in cantidadBrazos ? cantidadBrazos.value : []"
                :key="`b-${index}`"
              >
                <b-col cols="12">
                  <h3 class="text-warning">
                    Brazo No.{{ index+1 }}
                  </h3>
                  <br>
                </b-col>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Tipo de Brazo:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de Brazo"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.brazos[index].tipo"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="tiposBrazo"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Tamaño de Brazo:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tamaño de Brazo"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.brazos[index].tamaño"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="tamañosBrazo"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:4">
                  <h5 class="text-primary">
                    Estado del Brazo:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Estado del Brazo"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.brazos[index].estado"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="estadosBrazo"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col cols="12">
                  <h2 class="text-primary">
                    LÁMPARA
                  </h2>
                </b-col>
              </b-row>
              <b-row>
                <h3 class="text-warning" />
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Cantidad de Lámparas:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Cantidad de Lámparas"
                      rules="required"
                    >
                      <v-select
                        v-model="cantidadLamparas"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="cantidad"
                        :disabled="editable"
                        label="nombre"
                        @input="changeCombo('lampara')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(cantidad, index) in cantidadLamparas ? cantidadLamparas.value : []"
                :key="`l-${index}`"
              >
                <b-col cols="12">
                  <h3 class="text-warning">
                    Lámpara No.{{ index+1 }}
                  </h3>
                  <br>
                </b-col>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Tecnología de Lámpara:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tecnología de la Lámpara"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.lamparas[index].tecnologia"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="tecnologiasLampara"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Potencia de Lámpara:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Potencia de la Lámpara"
                      rules="required"
                    >
                      <b-form-input
                        v-model="detallePunto.lamparas[index].potencia"
                        :state="errors.length > 0 ? false:null"
                        type="number"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Estado de la Lámpara:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Estado de la Lámpara"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.lamparas[index].estado"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="estadosLampara"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Clase de la Lámpara:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Clase de la Lámpara"
                      rules="required"
                    >
                      <b-form-input
                        v-model="detallePunto.lamparas[index].modelo"
                        :state="errors.length > 0 ? false:null"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <br>
                  <hr>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col cols="12">
                  <h2 class="text-primary">
                    Cable
                  </h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col :cols="isNew?12:2">
                  <h5 class="text-primary">
                    Tipo de Cable:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de Cable"
                      rules="required"
                    >
                      <b-form-input
                        v-model="detallePunto.cable.tipo"
                        :state="errors.length > 0 ? false:null"
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :cols="isNew?12:3">
                  <h5 class="text-primary">
                    Estado de Cable:
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Estado de Cable"
                      rules="required"
                    >
                      <v-select
                        v-model="detallePunto.cable.estado"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :options="estadosCable"
                        :disabled="editable"
                        label="nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <b-row v-if="!isReadOnly">
                <b-col cols="12">
                  <br>
                  <hr>
                  <h2 class="text-primary">
                    Fotografía Panorámica de Levantamiento de Punto
                  </h2>
                </b-col>
                <b-col cols="12">
                  <br>
                  <imagen
                    :key="'datos-punto'"
                    :leyenda="'Datos Punto'"
                    @cargar-file="imagenPunto"
                  />
                  <hr>
                  <br>
                </b-col>
              </b-row>
              <b-row v-if="!isNew || (isNew && !isReadOnly)">
                <!-- BOTONES -->
                <b-col
                  v-if="editable"
                  cols="12"
                  class="demo-inline-spacing"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    @click.prevent="changeEditable"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Editar</span>
                  </b-button>
                </b-col>
                <b-col
                  v-if="!editable"
                  cols="12"
                  class="demo-inline-spacing"
                >
                  <b-button
                    variant="primary"
                    class="btn btn-primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ isNew ? 'Guardar' : 'Actualizar' }} Información</span>

                  </b-button>
                  <b-button
                    v-if="!isNew"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="mt-2"
                    type="reset"
                    @click.prevent="cancel"
                  >
                    <feather-icon
                      icon="SlashIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Cancelar</span>
                  </b-button>
                </b-col>
              </b-row>
            </BCard>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col
        v-if="!isNew && !isReadOnly"
        cols="5"
      >
        <b-card>
          <h2 class="text-primary">
            Historial de Cambios
          </h2>
          <b-row>
            <b-col>
              <vue-good-table
                :columns="columns"
                :rows="puntoIluminacion.historialDetalle"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'fecha'">
                    {{ calcularFecha(props.row.fecha) }}
                  </span>
                  <span v-if="props.column.field === 'usuario'">
                    {{ props.row.usuario.nombre }}
                  </span>
                  <span v-if="props.column.field === 'detalle'">
                    <modal-levantamiento-datos
                      :punto="puntoIluminacion"
                      :detalle="props.row"
                    />
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import Imagen from '@/components/Inspecciones/Imagen.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { updateCreatePoste } from '@/utils/postes'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getListadoDetalle } from '@/utils/detallePunto'
import { sendFile } from '@/utils/files'
import { calcularFecha } from '@/utils/fechas'
import ModalLevantamientoDatos from '@/components/PuntosIluminacion/modalLevantamientoDatos.vue'
import DetailPunto from '@/components/PuntosIluminacion/DetailPunto.vue'
import router from '@/router'

export default {
  name: 'LevantamientoDeDatos',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DetailPunto,
    ModalLevantamientoDatos,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    Imagen,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    puntoIluminacion: {
      type: Object,
      default: () => {
      },
    },
    isNew: {
      type: Boolean,
      default: false,
      required: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    detalle: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      cantidadBrazos: null,
      cantidadLamparas: null,
      imagen: null,
      detallePunto: {},
      estadosBrazo: [],
      tamañosBrazo: [],
      estadosLampara: [],
      tecnologiasLampara: [],
      propiedadesPoste: [],
      tamañosPoste: [],
      tiposPoste: [],
      observacionesPoste: [],
      tiposBrazo: [],
      estadosCable: [],
      cantidad: [{ nombre: '1', value: 1 }, { nombre: '2', value: 2 }],
      emailValue: '',
      name: '',
      required,
      email,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      showDetalle:
          router.currentRoute.params.id !== null
          && router.currentRoute.params.id !== undefined,
      columns: [
        {
          label: 'Fecha de Cambio',
          field: 'fecha',
        },
        {
          label: 'Usuario',
          field: 'usuario',
        },
        {
          label: 'Detalle',
          field: 'detalle',
        },
      ],
    }
  },
  async created() {
    this.cargarLogica()
    this.tiposPoste = getListadoDetalle(2)
    this.propiedadesPoste = getListadoDetalle(1)
    this.tamañosPoste = getListadoDetalle(3)
    this.observacionesPoste = getListadoDetalle(4)
    this.tiposBrazo = getListadoDetalle(5)
    this.tamañosBrazo = getListadoDetalle(6)
    this.estadosBrazo = getListadoDetalle(7)
    this.tecnologiasLampara = getListadoDetalle(8)
    this.estadosLampara = getListadoDetalle(9)
    this.estadosCable = getListadoDetalle(10)
  },
  methods: {
    verDetallePoste() {
      this.ocultarDialogo()
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
    },
    cargarLogica() {
      this.observacionesPoste = getListadoDetalle(4)
      // eslint-disable-next-line prefer-destructuring
      this.cantidadBrazos = this.cantidad[0]
      // eslint-disable-next-line prefer-destructuring
      this.cantidadLamparas = this.cantidad[0]
      if (this.isNew && !this.isReadOnly) {
        this.editable = false
        this.detallePunto = {
          propiedadPoste: null,
          tipoPoste: null,
          tamañoPoste: null,
          observacionesPoste: null,
          brazos: [
            {
              tipo: null,
              tamaño: null,
              estado: null,
            },
            {
              tipo: null,
              tamaño: null,
              estado: null,
            }],
          lamparas: [
            {
              tecnologia: null,
              potencia: '',
              estado: null,
              modelo: '',
            },
            {
              tecnologia: null,
              potencia: '',
              estado: null,
              modelo: '',
            },
          ],
          cable: {
            estado: null,
            tipo: '',
          },
        }
      } else if (this.isNew && this.isReadOnly) {
        this.detallePunto = this.detalle
      } else {
        this.detallePunto = JSON.parse(JSON.stringify(this.puntoIluminacion.detalle))
        // eslint-disable-next-line prefer-destructuring
        if (this.detallePunto.brazos[1].estado !== null) this.cantidadBrazos = this.cantidad[1]
        // eslint-disable-next-line prefer-destructuring
        if (this.detallePunto.lamparas[1].estado !== null) this.cantidadLamparas = this.cantidad[1]
      }
    },
    changeEditable() {
      this.cargarLogica()
      this.editable = false
    },
    async cancel() {
      this.editable = true
      this.cargarLogica()
    },
    async updateFiles(data) {
      if (!this.poste.files) this.poste.files = []

      if (data.opcion === 'ADD') {
        this.poste.files.push(data.file)
      } else {
        this.poste.files.splice(data.index, 1)
      }
      this.updateItem(false)
    },
    calcularFecha,
    imagenPunto(file) {
      this.imagen = file
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    changeCombo(origen) {
      if (origen === 'brazo') {
        if (this.cantidadBrazos === null || this.cantidadBrazos.value === 1) {
          this.detallePunto.brazos[1].tipo = null
          this.detallePunto.brazos[1].tamaño = null
          this.detallePunto.brazos[1].estado = null
          if (this.cantidadBrazos === null) {
            this.detallePunto.brazos[0].tipo = null
            this.detallePunto.brazos[0].tamaño = null
            this.detallePunto.brazos[0].estado = null
          }
        }
      }
      if (origen === 'lampara') {
        if (this.cantidadLamparas === null || this.cantidadLamparas.value === 1) {
          this.detallePunto.lamparas[1].tecnologia = null
          this.detallePunto.lamparas[1].potencia = ''
          this.detallePunto.lamparas[1].estado = null
          this.detallePunto.lamparas[1].modelo = ''
          if (this.cantidadLamparas === null) {
            this.detallePunto.lamparas[0].tecnologia = null
            this.detallePunto.lamparas[0].potencia = ''
            this.detallePunto.lamparas[0].estado = null
            this.detallePunto.lamparas[0].modelo = ''
          }
        }
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          if (this.imagen === null || this.imagen === undefined) {
            this.error('No se tiene una imagen cargada!')
            return
          }
          const urlBucket = `postes/${this.puntoIluminacion.id}`
          const saveImagenPoste = await sendFile(this.imagen, 'images', urlBucket)
          if (saveImagenPoste !== null) {
            saveImagenPoste.data.isMobile = window.screen.width <= 760
            saveImagenPoste.data.localization = this.localization
            saveImagenPoste.data.tipo = 'Imágen Panorámica Levantamiento de Punto'
            saveImagenPoste.data.fechaHora = new Date()
            this.puntoIluminacion.imagenes.push(saveImagenPoste.data)
            this.detallePunto.imagen = saveImagenPoste.data
          }
          if (!this.isNew) {
            if (this.puntoIluminacion.municipio !== null) this.puntoIluminacion.municipio = this.puntoIluminacion.municipio.id
            if (this.puntoIluminacion.localidad !== null) this.puntoIluminacion.localidad = this.puntoIluminacion.localidad.id
            if (this.puntoIluminacion.grupo !== null) this.puntoIluminacion.grupo = this.puntoIluminacion.grupo.id
            if (this.puntoIluminacion.modeloLampara !== null) this.puntoIluminacion.modeloLampara = this.puntoIluminacion.modeloLampara.id
            else delete this.puntoIluminacion.modeloLampara
            if (!this.puntoIluminacion.observaciones) delete this.puntoIluminacion.observaciones
            if (!this.puntoIluminacion.idAmpliacion) delete this.puntoIluminacion.idAmpliacion
            if (!this.puntoIluminacion.inspecciones) this.puntoIluminacion.inspecciones = []
            if (this.puntoIluminacion.tecnologiaLampara !== null) this.puntoIluminacion.tecnologiaLampara = this.puntoIluminacion.tecnologiaLampara.id
          }
          if (!this.puntoIluminacion.historialDetalle) this.puntoIluminacion.historialDetalle = []
          this.puntoIluminacion.historialDetalle.push(this.detallePunto)
          this.puntoIluminacion.detalle = this.detallePunto
          this.puntoIluminacion.detalle.fecha = new Date()
          this.puntoIluminacion.detalle.usuario = {
            id: this.usuario.id,
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
          }
          await updateCreatePoste(this.puntoIluminacion, 1)
          this.$router
            .replace('/puntos-iluminacion')
            .then(() => {
              this.success('Punto de Iluminación', 'Datos Ingresados Correctamente!!')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>

</style>
